img.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 0.07em !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important;
}

/*.entry-content p {
    margin-bottom: 24px;
}*/
.entry-content ul {
    margin: 0 0 1.6rem 1.6rem;
    padding-left: 0;
}

.entry-content a {
    color: #027ABB;
    text-decoration: underline;
}

div.calendarEvent {
    /*max-height: 50px;*/
    overflow-y: auto;
    /*white-space: nowrap;*/
    overflow: hidden;
    text-overflow: ellipsis;
    cursor:pointer;
}

.blackTextBorder {
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
}


@media (max-width: 500px) {
    h2.fc-toolbar-title {
        font-size: 1em !important;
    }

    button.fc-button {
        padding: 0.2em 0.45em !important;
    }

    span.fc-icon-chevron-left, span.fc-icon-chevron-right {
        font-size: 1em !important;
    }

    div.fc-list-day-cushion a {
        font-size: 0.8em;
    }

    tr.fc-event {
        border-bottom: 1px solid #ddd;
    }

    tr.fc-event td:nth-of-type(1), tr.fc-event td:nth-of-type(3) {
        display: block;
        border: 0;
        padding: 3px 7px;
        font-size: 0.8em;
    }

    tr.fc-event td:nth-of-type(2) {
        display: none;
    }
}